@use "../globals" as *;

$modalOverlayBackground: rgba(0,0,0,0.6);
$closeButtonSize: 50px;
$maxWidth: 75vw;
$maxHeight: 90vh;
$minWidth: 200px;
$minHeight: 100px;

.ReactModalPortal,
#content {

  [data-micromodal-trigger] {
    cursor: pointer;
  }

  .ReactModal__Html--open,
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $modalOverlayBackground;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }

  .ReactModal__Content {
    position: relative;
    max-width: $maxWidth;
    max-height: $maxHeight;
    min-width: $minWidth;
    min-height: $minHeight;
    background: white;
    overflow-y: auto;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    &.padding__{
      &normal {
        padding: $spacer-half;
      }
      &big {
        padding: $spacer;
      }
      &none {
        padding: 0;
      }
    }

    //special ruleset for dutylist
    #dutyList {
      height: 100%;
      width: 100%;
      //hide embed element on mobile
      @include media-above($mobile) {
        .duty-header {
          display: none;
        }
      }
      @include media-below($mobile) {
        embed {
          display: none;
        }
        .duty-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .btn {
            margin: $spacer-half;
            font-size: 20px;
            display: inline-block;
            padding: $spacer-half $spacer;
            text-decoration: none;
            transition: all 250ms ease-in-out;
            cursor: pointer;
            background-color: $primary;
            border: 2px solid $primary;
            color: $white;
            &:hover,
            &:focus {
              background-color: darken($primary, 10);
              border: 2px solid darken($primary, 10);
              color: $white;
              transform: scale(1.025);
            }
          }
        }
      }
    }
  }

  .modal__content {
    margin-left: -$spacer-half;
    margin-right: -$spacer-half;
  }

  .modal__container header {
    min-height: $closeButtonSize;
  }

  .modal__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: $closeButtonSize;
    height: $closeButtonSize;
    color: $black;

    cursor: pointer;
    background: transparent;
    border: 0;

    &:before {
      content: "x";
    }
    &:hover {
      background: rgba($black, 0.075);
    }
  }

  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }

}

%positive-coloration {
  .modal__close {
    color: $black;
    &:hover {
      background: rgba($black, 0.075);
    }
  }
}

%negative-coloration {
  .modal__close {
    color: $white;
    &:hover {
      background: rgba($white, 0.075);
    }
  }
}
