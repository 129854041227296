@use "../globals" as *;

#content {
  .sticky-button {
    position: fixed;
    right: 0;
    top: $spacer;
    z-index: 1000000;
    background-color: $primary;
    color: $white;
    padding: $spacer-half $spacer;
    transition: all 250ms ease-in-out;
    transform: translateX(101%);
    &.active {
      transform: translateX(0);
    }
    @include media-below($tablet) {
      font-size: 1.4rem;
      padding: $spacer-third $spacer-half;
    }
    &:hover,
    &:focus {
      padding-right: $spacer*2;
      background-color: darken($primary, 10);
    }
  }
}

%positive-coloration {

}

%negative-coloration {

}
