@use "../globals" as *;

#content {

  .video,
  .video-container {
    max-height: 888px;
    align-items: center;
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1000000;

    .embed-video,
    .embed-video-16by9,
    .embed-video-header {
      display: block;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 100%;
      z-index: 0;

      &::before {
        display: block;
        content: "";
        padding-top: 56.25%;
      }

      iframe,
      video,
      &-poster {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      &-poster {
        background-size: cover;
        background-color: $black;
      }

      @include media-below($md) {
        margin-top: 112px;
      }
    }

  }

  .video-logo {
    position: absolute;
    top: $spacer;
    left: 0;
    right: 0;
    z-index: 1;
    img {
      max-width: 200px;
      height: auto;
      @include media-below($tablet) {
        max-width: 140px;
      }
    }
  }

}

%positive-coloration {

}

%negative-coloration {

}
