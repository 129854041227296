@use "../globals" as *;

.react-collapsible-theme,
.ReactModalPortal,
#content {

  // Table-Wrapper needs this rule to scroll horizontal on < 600px width
  figure.table,
  .table-wrapper {
    overflow-x: auto;
  }

  table,
  table.react-collapsible {
    border-collapse: collapse;
    width: 100%;
    line-height: 1.5;
    text-align: left;
    font-size: $font-text-small;
    //min-width: 600px;

    thead {
      text-transform: initial !important;
      font-size: $font-text-small !important;
      color: $black !important;
    }

    p {
      font-size: $font-text-small;
    }

    td {
      vertical-align: top;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      padding: $spacer-half-half;
      text-align: left !important;
      font-size: $font-text-small;
    }

    tr {
      vertical-align: top;
      &:hover {
        cursor: default;
      }
    }

    th {
      vertical-align: top;
      font-weight: bold;
      padding: $spacer-half-half;
    }

    span:has(.brand-primary-light) {
      display: inline-flex;
    }
    .brand-primary-light {
      color: $primary;
      width: 30px;
      height: 27px;
      display: inline-block;
      cursor: pointer;
    }
    .expanded-row {
      td {
        //border-bottom: 60px solid transparent;
      }
    }
  }

  .noTableBg table {
    td {
      border-bottom: 1px solid transparent !important;
      padding: 13px;
    }
    tr {
      background-color: transparent !important;
      &:nth-child(even):not(.expanded-row) {
        background-color: transparent !important;;
      }
    }
  }

}

%positive-coloration {
  table {
    td {
      border-bottom: 1px solid rgba($black, 0.25);
    }
    tr {
      background-color: rgba($white, 0.7);
    }
    &.react-collapsible thead tr {
      border-bottom: 2px solid rgba($black, 0.35);
    }
  }
}

%negative-coloration {
  table {
    td {
      border-bottom: 1px solid rgba($white, 0.25);
    }
    tr {
      background-color: rgba($black, 0.7);
    }
    &.react-collapsible thead tr {
      border-bottom: 2px solid rgba($white, 0.35);
    }
  }
}

// THIS IS FOR NEOS:
//ckeditor overrides this properties, so !important is important lol
.ck-content .table {
  width: 100% !important;
}
.ck-content .table table td, .ck-content .table table th {
  text-align: inherit !important;
}
