@use "../globals" as *;

footer {
  font-size: $font-text-small;
  p {font-size: $font-text-small;}

  .container {
    padding-top: $spacer !important;
    padding-bottom: $spacer !important;
  }
  ul {
    display: flex;
    list-style-type: none !important;
    padding-left: 0 !important;
    margin: 0 !important;
    li {
      margin-right: $spacer;
      a {
        display: flex;
        color: $black !important;
        border-bottom: 1px dashed transparent;
        &:hover,
        &:focus {
          color: $primary !important;
          border-bottom: 1px dashed $primary;
        }
      }
    }
  }
}

%positive-coloration {

}

%negative-coloration {

}
