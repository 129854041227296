@use "../globals" as *;

[class^="col-"] p:last-child,
[class*="col-"] p:last-child,
.con-spacer p:last-child,
.con-spacer-big p:last-child {
  margin-bottom: 0;
}


// Utilities
b,
strong,
.strong {
  font-family: $font-family-bold;
  font-weight: bold;
}

i,
em,
.italic {
  font-style: italic;
}

.text-align-left {
  text-align: left;
  align-items: flex-start;
}
.text-align-right {
  text-align: right;
  align-items: flex-end;
}
.text-align-center {
  text-align: center;
  align-items: center;
}

sub {
  vertical-align: sub;
  font-size: $font-sub-sup;
}

sup {
  vertical-align: super;
  font-size: $font-sub-sup;
}

small {
  font-size: 14px;
}

.big {
  font-size: $font-text-big;
  font-family: $font-family-bold;
  line-height: 1.2;
  margin-bottom: $spacer*2 !important;
  @include media-below($font-text-breakpoint) {
    font-size: $font-text-breakpoint-big-small;
    margin-bottom: $spacer*1.5 !important;
  }
}

.small {
  font-size: $font-text-small;
  line-height: 1.2;
}
