@use "globals" as *;

/*
If you have to change something in the base layout.
Do it here!
*/

html {
  scroll-behavior: smooth;
}

body {
  font-variation-settings: "wght" 400;
  background-color: $white;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.bg-image {
  background-color: rgba(20,24,29,1);
  background-repeat: no-repeat;
  background-position: center;
  @include media-below($xl) {
    background-size: 750px;
    background-position: center right;
  }
  @include media-below($lg) {
    background-size: 600px;
    background-position: 80% 5%;
  }
}

.container {
  overflow: hidden;
}

.t-shadow {
  text-shadow: 0 0 10px black;
}

.btn {
  min-width: 310px;
  text-align: center !important;
}
