@use "../globals" as *;

form {
  input[type="text"],
  textarea {
    border: 1px solid $black;
    font-size: 24px;
    padding: 20px 30px;
    width: 100%;
    &:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }

  textarea {
    //not resizable and default font
    resize: vertical;
    font-family: $font-family-regular;
  }

  label {
    font-size: 20px;
  }

  input[type="submit"] {
    font-size: 24px;
    background-color: $primary;
    color: $white;
    border: 0;
    padding: 20px 30px;
    cursor: pointer;
  }

  // Define styles for the custom radio buttons
  input[type='radio'] {
    display: none;
  }

  // Create a class for the radio button labels
  .custom-radio {
    display: inline-block;
    position: relative;
    padding-right: $spacer;

    span {
      padding-top: 10px;
    }
  }

  /*
  * Create a custom radio button
   */

  // Style the radio button dot
  .custom-radio::before {
    content: '';
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: relative;
    background-color: #fff;
    border: 8px solid white;
    display: inline-block;
    margin-right: 10px;
    margin-top: 1px;
    margin-left: 1px;
    z-index: 1;
  }
  //add a after element that is under the before element and looks like a black border around the before
  .custom-radio::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
    border: 5px solid $black;
    display: inline-block;
    margin-right: 10px;
    top: 0;
    left: 0;
  }

  // Style the radio button when it is checked
  input[type='radio']:checked + .custom-radio::before {
    background-color: $primary;
    //place the background so that there is a white border around it
  }

  /*
  * Create a custom Datepicker
   */

  .date-picker {

    .react-datepicker,
    .react-datepicker__current-month {
      font-size: 16px;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      width: 30px;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: $primary;
      color: $white;
    }


    @include media-below($mobile)
    {
      &.react-datepicker {
        animation-name: none !important;
      }

      .react-datepicker-wrapper,
      .desktop-datepicker {
        display: none;
      }


      @supports (-webkit-touch-callout: none) {
        .mobile-datepicker {
          display: block;
        }
      }

      @supports not (-webkit-touch-callout: none) {
        .mobile-datepicker:focus {
          display: none;
        }
      }


      .react-datepicker__portal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
      }
    }

    @include media-above($mobile)
    {
      .mobile-datepicker {
        display: none;
      }
    }
  }
}

// MOBILE Overwrites

@include media-below($mobile)
{
  form {
    input[type="text"],
    textarea {
      font-size: 16px;
      padding: 10px 15px;
    }

    label {
      font-size: 16px;
    }

    input[type="submit"] {
      font-size: 16px;
      padding: 10px 15px;
    }

    .custom-radio::before {
      width: 22px;
      height: 22px;
      border-width: 5px;
    }
    .custom-radio::after {
      width: 24px;
      height: 24px;
    }
  }
}
