.lottie-parent {
  //position: relative;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100000;
  opacity: 0.4;
  pointer-events: none;
}

.lottie-static {
  > * {display: flex;}
}

.lottie-sticky {
  position: sticky;
  top: 0;
  > * {display: flex;}
}
