@use "../globals" as *;

#content {
  .text-animation {
    margin-bottom: $spacer*2;
    & + .big {
      margin-top: -$spacer*2;
    }
    @include media-below($font-text-breakpoint) {
      margin-bottom: $spacer*1.5;
      & + .big {
        margin-top: -$spacer*1.5;
      }
    }
  }

  .text-animation {
    position: relative;
    display: inline-block;
    font-size: $font-scroll-text-animation;
    color: transparent;
    -webkit-text-stroke: 2px $black;
    text-stroke: 2px $black;
    text-transform: uppercase;
    line-height: 1;
    white-space: nowrap;

    @include media-below($xl) { //660px
      font-size: $font-scroll-text-animation-small;
    }

    @include media-below(660px) { //660px
      font-size: $font-scroll-text-animation-smaller;
      text-stroke: 1px $black;
      -webkit-text-stroke: 1px $black;
      &:before {
        -webkit-text-stroke: 2px $primary !important;
        text-stroke: 2px $primary !important;
      }
    }
  }

  .text-animation::before {
    /* This will create the layer
       over original text*/
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    white-space: nowrap;

    /* Setting different color than
       that of original text  */
    color: $primary;
    -webkit-text-stroke: 3px $primary;
    text-stroke: 3px $primary;
    overflow: hidden;

    /* Setting width as 0*/
    width: 0;
    transition: 2s;
  }

  .text-animation.ta-active::before {
    /* Setting width to 100 on when active*/
    width: 100%;
  }
}

%bg-white-coloration {

}

%positive-coloration {

}

%negative-coloration {

}
